import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'signin',
    component: () => import('@/views/authentication/Login.vue'),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/layouts/Content.vue"),
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views//dashboard/Dashboard.vue"),
        meta: {
          title: "Dashboard"
       }
      },
      {
        path: "/workflow",
        name: "workflow",
       component: () => import("@/views/workflow/Workflow.vue"),
        meta: {
          title: "Workflow"
       }
      },
      {
        path: "/workflow/newestimate",
        name: "newestimate",
        component: () => import("@/views/workflow/AddNewEstimate.vue"),
        meta: {
          title: "New Estimate"
       }
      },
      {
        path: "/workflow/:id/edit",
        name: "editestimate",
        component: () => import("@/views/workflow/EditNewEstimate.vue"),
        meta: {
          title: "Edit Estimate"
       }
      },
      {
        path: "/appointment",
        name: "appointment",
        component: () => import("@/views/appointment/Appointment.vue"),
        meta: {
          title: "Appointments"
       }
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/Calendar.vue"),
        meta: {
          title: "Calendar"
       },
      },
      {
        path: "/calendar/createappointment",
        name: "addnewappointment",
        component: () => import("@/views/calendar/AddNewAppointment.vue"),
        meta: {
          title: "Create New Appointment"
         }
        },
        {
          path: "/calendar/editappointment/:id/edit",
          name: "editappointment",
          component: () => import("@/views/calendar/EditAppointment.vue"),
          meta: {
            title: "Edit Appointment"
           }
       },
       {
        path: "/inventories",
        name: "inventory",
        component: () =>
          import("@/views/inventory/Inventory.vue"),
          meta: {
            title: "Inventory List"
         }
      },
      {
        path: "/inventories/parts",
        name: "addnewpart_inventory",
        component: () =>
          import("@/components/inventory/Part/AddNewPart.vue"),
          meta: {
            title: "Add New Part Inventory"
         }
      },
      {
        path: "/inventories/parts/:id/edit",
        name: "editpart_inventory",
        component: () =>
          import("@/components/inventory/Part/EditPart.vue"),
          meta: {
            title: "Edit Part Inventory"
         }
      },
      {
        path: "/inventories/tires",
        name: "addnewtire_inventory",
        component: () =>
          import("@/components/inventory/Tire/AddNewTire.vue"),
          meta: {
            title: "Add New Tire Inventory"
         }
      },
      {
        path: "/inventories/tires/:id/edit",
        name: "edittire_inventory",
        component: () =>
          import("@/components/inventory/Tire/EditTire.vue"),
          meta: {
            title: "Edit Tire Inventory"
         }
      },
      {
        path: "/inventories/labors",
        name: "addnewlabor_inventory",
        component: () =>
        import("@/components/inventory/Labor/AddNewLabor.vue"),
          meta: {
            title: "Add New Labor Inventory"
         }
      },
      {
        path: "/inventories/labors/:id/edit",
        name: "editlabor_inventory",
        component: () =>
        import("@/components/inventory/Labor/EditLabor.vue"),
          meta: {
            title: "Edit Labor Inventory"
         }
      },
      {
        path: "/purchase-orders",
        name: "purchaselist",
        component: () =>
          import("@/views/purchasing/purchasing.vue"),
          meta: {
            title: "Purchasing List"
         }
      },
      {
        path: "/purchase-orders/new",
        name: "addnew_purchaseorder",
        component: () =>
          import("@/components/purchasing/AddNewPurchaseOrder.vue"),
          meta: {
            title: "Add New Purchase Order"
         }
      },  
      {
        path: "/purchase-orders/:id/edit",
        name: "edit_purchaseorder",
        component: () =>
          import("@/components/purchasing/EditPurchaseOrder.vue"),
          meta: {
            title: "Edit Purchase Order"
         }
      },
      {
        path: "/purchase-orders/order/:id",
        name: "show_ordered",
        component: () =>
          import("@/components/purchasing/NewOrdered.vue"),
          meta: {
            title: "Mark as Ordered"
         }
      },     
      {
        path: "/purchase-orders/receive/:id",
        name: "show_ordered-received",
        component: () =>
          import("@/components/purchasing/NewOrderedReceived.vue"),
          meta: {
            title: "Mark as Order Received"
         }
      }, 
      {
        path: "/purchase-orders/return/:id",
        name: "show_return-orders",
        component: () =>
          import("@/components/purchasing/ReturnOrder.vue"),
          meta: {
            title: "Mark as Return Order"
         }
      },  
      {
        path: "/settings/brands",
        name: "brand",
        component: () =>
          import("@/views/settings/brand/Brands.vue"),
          meta: {
            title: "Brands"
         }
      },           
      {
        path: "/clocks",
        name: "clock",
        component: () =>
          import("@/views/clock/Clock.vue"),
          meta: {
            title: "Time Clocks"
         }
      },   
      {
        path: "/lists/customers",
        name: "customer",
        component: () =>
          import("@/views/customer/Customer.vue"),
          meta: {
            title: "Customers"
         }
      },  
      {
        path: "/lists/customer/addnew",
        name: "addnewcustomer",
        component: () =>
          import("@/views/customer/AddNewCustomer.vue"),
          meta: {
            title: "Add New Customer"
         }
      },  
      {
        path: "/lists/customer/:id/edit",
        name: "editcustomer",
        component: () =>
          import("@/views/customer/EditCustomer.vue"),
          meta: {
            title: "Edit Customer"
         }
      }, 
      {
        path: "/lists/vehicles",
        name: "vehicle",
        component: () =>
          import("@/views/vehicle/Vehicle.vue"),
          meta: {
            title: "Vehicles"
         }
      },   
      {
        path: "/lists/vehicles/addnew",
        name: "addnewvehicle",
        component: () =>
          import("@/views/vehicle/AddNewVehicle.vue"),
          meta: {
            title: "Add New Vehicle"
         }
      }, 
      {
        path: "/lists/vehicles/:id/editvehicle",
        name: "editvehicle",
        component: () =>
          import("@/views/vehicle/EditVehicle.vue"),
          meta: {
            title: "Edit Vehicle"
         }
      },
      {
        path: "/lists/vendors",
        name: "vendor",
        component: () =>
          import("@/views/vendor/Vendor.vue"),
          meta: {
            title: "Vendors"
         }
      }, 
      {
        path: "/lists/vendor/addnew",
        name: "addnewvendor",
        component: () =>
          import("@/views/vendor/AddNewVendor.vue"),
         
          meta: {
            title: "Add New Vendor"
         }
      },
      {
        path: "/lists/vendor/:id/edit",
        name: "editvendor",
        component: () =>
        import("@/views/vendor/EditVendor.vue"),
          meta: {
            title: "Edit Vendor"
         }
      },
      {
        path: "/lists/fleets",
        name: "fleet",
        component: () =>
          import("@/views/fleet/Fleet.vue"),
          meta: {
            title: "Fleets"
         }
      },
      {
        path: "/lists/inspectioncategories",
        name: "inspectioncategorylist",
        component: () =>
          import("@/views/inspectioncategory/InspectionCategoryList.vue"),
          meta: {
            title: "Inspection Categories"
         }
      },
      {
        path: "/lists/inspectiontemplates",
        name: "inspectiontemplate",
        component: () =>
          import("@/views/inspectiontemplate/InspectionTemplateList.vue"),
          meta: {
            title: "Inspection Templates"
         }
      },
      {
        path: "/lists/inspectiontemplates",
        name: "addnewinspectiontemplate",
        component: () =>
          import("@/views/inspectiontemplate/AddNewInspectionTemplate.vue"),
          meta: {
            title: "New Inspection Templates"
         }
      },
      {
        path: "/lists/inspectiontemplates",
        name: "editnewinspectiontemplate",
        component: () =>
          import("@/views/inspectiontemplate/EditNewInspectionTemplate.vue"),
          meta: {
            title: "Edit Inspection Template"
         }
      },
      {
        path: "/lists/cannedservices",
        name: "cannedservice",
        component: () =>
          import("@/views/cannedservice/CannedServices.vue"),
          meta: {
            title: "Canned Service List"
         }
      },
      {
        path: "/lists/addnewcannedservices",
        name: "addnewcannedservice",
        component: () =>
          import("@/views/cannedservice/AddNewCannedService.vue"),
          meta: {
            title: "Add New Canned Service"
         }
      },
      {
        path: "/lists/editnewcannedservices/:id/edit",
        name: "editnewcannedservice",
        component: () =>
          import("@/views/cannedservice/EditNewCannedService.vue"),
          meta: {
            title: "Edit New Canned Service"
         }
      },
      {
        path: "/lists/inventorycategories",
        name: "inventorycategory",
        component: () =>
          import("@/views/inventorycategory/InventoryCategoryList.vue"),
          meta: {
            title: "Inventory Categories"
         }
      },
      {
        path: "/settings/pricing-matrix",
        name: "pricingMatrix",
        component: () => import("@/views/settings/pricing/Pricing.vue"),
        meta: {
          title: "Pricing Matrix"
       }
      },
      {
        path: "/settings/labor-matrix",
        name: "laborMatrix",
        component: () => import("@/views/settings/labor/Labor.vue"),
        meta: {
          title: "Labor Matrix"
       }
      },
      {
        path: "/settings/rates",
        name: "rate",
        component: () => import("@/views/settings/rate/Rates.vue"),
        meta: {
          title: "Rates"
       }
      },
      {
        path: "/settings/users",
        name: "user",
        component: () => import("@/views/settings/user/User.vue"),
        meta: {
          title: "Users"
       },
      },
      {
        path: "/settings/user/addnew",
        name: "addnewuser",
        component: () =>
          import("@/views/settings/user/AddNewUser.vue"),
          meta: {
            title: "Add New User"
         }
      },  
      {
        path: "/settings/user/:id/edit",
        name: "edituser",
        component: () =>
        import("@/views/settings/user/EditUser.vue"),
          meta: {
            title: "Edit User"
         }
      },
      {
        path: "/settings/roles",
        name: "role",
        component: () => import("@/views/settings/role/Role.vue"),
        meta: {
          title: "Roles"
       },
      },
      {
        path: "/settings/role/addnewrole",
        name: "addnewrole",
        component: () =>
          import("@/views/settings/role/AddNewRole.vue"),
          meta: {
            title: "Add New Role"
         }
      },  
      {
        path: "/settings/role/:id/edit",
        name: "editrole",
        component: () =>
        import("@/views/settings/role/EditRole.vue"),
          meta: {
            title: "Edit Role"
         }
      },
      {
        path: "/settings/permssions",
        name: "permission-list",
        component: () =>
          import("@/views/settings/userpermission/Permission.vue"),
          meta: {
            title: "Roles and Permissions"
         }
      },  
      {
        path: "/settings/permissions/create",
        name: "add_permission",
        component: () =>
          import("@/views/settings/userpermission/AddNewPermission.vue"),
          meta: {
            title: "Add Permission"
         }
      },  
      {
        path: "/settings/permissions/:id/edit",
        name: "edit_permission",
        component: () =>
          import("@/views/settings/userpermission/EditNewPermission.vue"),
          meta: {
            title: "Edit Permission"
         }
      },  

      {
        path: "/settings/userlogs",
        name: "logUser",
        component: () =>
          import("@/views/settings/userlogs/UserLogs.vue"),
          meta: {
            title: "User Logs"
         }
      },  
      {
        path: "/settings/customerlogs",
        name: "logCustomer",
        component: () =>
          import("@/views/settings/userlogs/CustomerLogs.vue"),
          meta: {
            title: "Customer Logs"
         }
      },  

      {
        path: "/settings/vehiclelogs",
        name: "logVehicle",
        component: () =>
          import("@/views/settings/userlogs/VehicleLogs.vue"),
          meta: {
            title: "Vehicle Logs"
         }
      },  
      {
        path: "/settings/clocklogs",
        name: "logClock",
        component: () =>
          import("@/views/settings/userlogs/TimesheetLogs.vue"),
          meta: {
            title: "Clock Logs"
         }
      },  
    ]
  },
    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
