import axios from "../../api/api.shopcommander";

const state = {
  estimates: [],
  customers:[],
  vehicles: [],
  upcoming_appointments: [],
  previous_appointments: []
 
};

const getters = {
  fetchEstimates: (state) => {
    return state.estimates;
  },
  fetchEstimate: (state) => {
    return state.estimate;
  },
  estimateNumber: (state) => {
    return state.estimates;
  },
  getEstimate: (state) => {
    return state.estimates;
  },
  selectCustomer: (state) => {
    return state.customers;
  },
  selectVehicle: (state) => {
    return state.vehicles;
  },
  searchVehicleLookup: (state) => {
    return state.vehicles;
  }
};

const mutations = {
  SET_ESTIMATES: (state, payload) =>{
    state.estimates = payload;
  },
    
  SET_CUSTOMERS: (state, payload) =>{
    state.customers = payload;
  },
  SET_VEHICLES: (state, payload) =>{
    state.vehicles = payload;
  },
  ADD_ESTIMATE: (state, payload) => {
    state.estimates = Object.assign({}, payload);
  },

  UPDATE_ESTIMATE_NO: (state, payload) =>{
    state.estimates = payload
  },
  UPDATE_ESTIMATE: (state, payload) => {
   state.estimates = Object.assign({}, payload);
  },
  REMOVE_ESTIMATE: (state, id) => {
    state.estimates = state.estimates.filter((item) => item.id !== id);
  },
  SET_UPCOMING_EVENTS: (state, payload) =>{
    state.upcoming_appointments = payload;
  },
  SET_PREVIOUS_EVENTS: (state, payload) =>{
    state.previous_appointments = payload;
  },
};

const actions = {
  async fetchEstimates({ commit }) {
    await axios
      .get(`estimates`
      )
      .then((response) => {
        commit("SET_ESTIMATES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchNewEstimate({ commit }) {
    await axios
      .post(`estimates/newestimate`
      )
      .then((response) => {
        commit("SET_ESTIMATES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async editEstimate({ commit }, payload) {
    await axios
    .get(`estimates/${payload.estimate_id}/edit`)
    .then((response) => {
      commit("SET_ESTIMATES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async createEstimate({ commit }) {
    await axios
      .post(`estimates/create`)
      .then((response) => {
        commit("SET_ESTIMATES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
 
  async searchVehicleLookup({ commit }, payload) {
    await axios
      .get(`vehicle/searchvehicle?page=${payload.data.page}&draw=${payload.data.draw}&length=${payload.data.length}&search=${payload.data.search}`)
      .then((response) => {
        commit("SET_VEHICLES", response.data.data.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async selectCustomer({ commit }) {
    await axios
      .post(`customers/selectcustomer`)
      .then((response) => {
        commit("SET_CUSTOMERS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async selectEstimate({ commit }) {
    await axios
      .post(`estimates/selectestimate`)
      .then((response) => {
        commit("SET_ESTIMATES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async selectVehicle({ commit }) {
    await axios
      .post(`vehicles/selectvehicle`)
      .then((response) => {
        commit("SET_VEHICLES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
       
  async storeNewEstimate({ commit }, payload) {
    await axios
      .post('/estimates', payload)
      .then((response) => {
        commit("ADD_ESTIMATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewEstimate({ commit }, payload) {
    await axios
      .patch(`estimates/${payload.estimate_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_ESTIMATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteEstimate({ commit }, estimate) {
    await axios
      .delete(`estimates/${estimate.id}`)
      .then((response) => {
        commit("REMOVE_ESTIMATE", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async fetchUpcomingAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
    axios.post(`estimates/upcomingevents`)
      .then((response) => {
        commit("SET_UPCOMING_EVENTS", response.data.data);
        resolve(response.data);
        
      })
      .catch((err) => {
        console.log(err);
      });
    })
  },
  async fetchPreviousAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
    axios.post(`estimates/previousevents`)
      .then((response) => {
        commit("SET_PREVIOUS_EVENTS", response.data.data);
        resolve(response.data);
        // console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    })
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
