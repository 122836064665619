import axios from "../../api/api.shopcommander";

const state = {
  users: [],
  permissions: [],
 
 
};

const getters = {
  fetchUsers: (state) => {
    return state.users;
  },

  fetchPermissions: (state) => {
    return state.permissions;
  },
};

const mutations = {

  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },

  SET_USERS: (state, users) => {
    state.users = users;
  },

  SET_USER: (state, user) => {
    state.user = user;
  },

  ADD_USER: (state, payload) => {
    state.users = Object.assign({}, payload);
  },
  UPDATE_USER: (state, payload) => {
    state.users = Object.assign({}, payload);
  },

  REMOVE_USER: (state, id) => {
    state.users = state.users.filter((item) => item.id !== id);
  },
  RESTORE_USER: (state, payload) => {
    state.users = Object.assign({}, payload)
  },

  UPDATE_STATUS: (state, payload) => {
    state.users.forEach(user => {
      user.status = payload;
    });
  }
};

const actions = {
  async fetchUsers({ commit }) {
    await axios
      .get(`users`)
      .then((response) => {
        commit("SET_USERS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
    axios.get(`users/${payload.user_id}`)
      .then((response) => {
        commit("SET_USER", response.data.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    })
  },

  async editUser({ commit }, payload) {
    await axios
    .get(`users/${payload.user_id}/edit`)
    .then((response) => {
      commit("SET_USERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  async storeNewUser({ commit }, payload) {
    await axios
      .post("users", payload)
      .then((response) => {
        commit("ADD_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateUserPermission({ commit }, payload) {
    await axios
      .post("users/permission", payload)
      .then((response) => {
        commit("ADD_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewUser({ commit }, payload) {
    await axios
      .patch(`users/${payload.data.user_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteUser({ commit }, payload) {
    await axios
      .delete(`users/${payload.user_id}`)
      .then((response) => {
        commit("REMOVE_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async editUserPermission({ commit }, payload) {
    await axios
    .post(`permissions/edit/${payload.user_id}`)
    .then((response) => {
      commit("SET_PERMISSIONS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async deletedUserPermission({ commit }) {
    await axios
    .post(`users/logs/deleted`)
    .then((response) => {
      commit("SET_USERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async restoredeletedUser({ commit }, payload) {
    await axios
    .post(`users/logs/${payload.user_id}/restored`)
      .then((response) => {
        commit("RESTORE_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
