import axios from "../../api/api.shopcommander";
const state = {
  vehicles: []
};

const getters = {
  fetchVehicles: (state) => {
    return state.vehicles;
  },
    
};

const mutations = {
  SET_VEHICLES(state, vehicles){
    state.vehicles = vehicles;
  },
  
  ADD_VEHICLE: (state, payload) => {
    state.vehicles = Object.assign({}, payload)
  },
  UPDATE_VEHICLE: (state, payload) => {
    state.vehicles = Object.assign({}, payload)
  },
  REMOVE_VEHICLE: (state, payload) => {
    state.vehicles = Object.assign({}, payload)
  },
  RESTORE_VEHICLE: (state, payload) => {
    state.users = Object.assign({}, payload)
  },
};

const actions = {
  async fetchVehicles({ commit }) {
    await axios
    .get(`vehicles`)
    .then((response) => {
      commit("SET_VEHICLES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async editVehicle({ commit }, payload) {
    await axios
    .get(`vehicles/${payload.vehicle_id}/edit`)
    .then((response) => {
      commit("SET_VEHICLES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
   
  async storeNewVehicle({ commit }, payload) {
    
    await axios
      .post('vehicles', payload)
      .then((response) => {
        commit("ADD_VEHICLE", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewVehicle({ commit }, payload) {
    await axios
      .put(`vehicles/${payload.vehicle_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_VEHICLE", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteVehicle({ commit }, payload) {
    console.log(payload);
    await axios
      .delete(`vehicles/${payload.vehicle_id}`)
      .then((response) => {
        // commit("REMOVE_VEHICLE", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletedVehicleLogs({ commit }) {
    await axios
    .post(`vehicles/logs/deleted`)
    .then((response) => {
      commit("SET_VEHICLES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async restoredeletedVehicleLogs({ commit }, payload) {
    await axios
    .post(`vehicles/logs/${payload.vehicle_id}/restored`)
      .then((response) => {
        commit("RESTORE_VEHICLE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
