import axios from "../../api/api.shopcommander";

const state = {
  technicianConcerns: []
};

const getters = {
  fetchTechnicianConcerns: (state) => {
    return state.technicianConcerns;
  },
};

const mutations = {
  SET_TECHNICIAN_CONCERNS(state, payload){
    state.technicianConcerns = payload;
  },
 
  ADD_TECHNICIAN_CONCERN(state, payload){
    state.technicianConcerns = Object.assign({}, payload);
  },
  UPDATE_TECHNICIAN_CONCERN(state, payload){
    state.technicianConcerns = Object.assign({}, payload);
  },
  REMOVE_TECHNICIAN_CONCERN: (state, id) => {
    (state.technicianConcerns = state.technicianConcerns.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchTechnicianConcerns({ commit }, payload) {
    await axios
    .get(`technicianconcerns?estimate_id=${payload.estimate_id}`)
    .then((response) => {
      commit("SET_TECHNICIAN_CONCERNS", response.data);
    })
      .catch((err) => {
        console.log(err);
      });
    
  },
  
  async storeNewTechnicianConcern({ commit }, payload) {
     await axios
      .post('technicianconcerns', payload)
      .then((response) => {
        commit("ADD_TECHNICIAN_CONCERN", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewTechnicianConcern({ commit }, payload) {
    await axios
      .patch(`technicianconcerns/${payload.tech_concern_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_TECHNICIAN_CONCERN", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteTechnicianConcern({ commit }, payload) {
    await axios
      .delete(`technicianconcerns/${payload.tech_concern_id}`)
      .then((response) => {
        commit("REMOVE_TECHNICIAN_CONCERN", response.data);   
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
