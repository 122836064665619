import axios from "../../api/api.shopcommander";

const state = {
  rates: [],
 
};

const getters = {
  fetchRates: (state) => {
    return state.rates;
  },

};

const mutations = {
  SET_RATES(state, payload){
    state.rates = payload;
  },
   ADD_RATE: (state, payload) => {
    state.rates = Object.assign({}, payload)
  },
  UPDATE_RATE: (state, payload) => {
    state.rates = Object.assign({}, payload)
  },
  REMOVE_RATE: (state, id) => {
    (state.rates = state.rates.filter((item) => item.id !== id))
  },
 }

const actions = {
  async fetchRates({ commit }) {
    await axios
    .get(`rates`)
    .then((response) => {
      commit("SET_RATES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async storeNewRate({ commit }, payload) {
    await axios
      .post('/rates', payload)
      .then((response) => {
        commit("ADD_RATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async updateNewRate({ commit }, payload) {
    await axios
      .patch(`rates/${payload.rate_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_RATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteRate({ commit }, payload) {
    await axios
      .delete(`rates/${payload.rate_id}`)
      .then((response) => {
        commit("REMOVE_RATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
