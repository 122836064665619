import axios from "../../api/api.shopcommander";
const state = {
  makes: [],
  years:[]
};

const getters = {
  fetchMakes: (state) => {
    return state.makes;
  },
 
  
};

const mutations = {
 
  SET_MAKES: (state, makes) => {
    state.makes = makes;
  },
  ADD_MAKE: (state, payload) => {
    state.makes = Object.assign({}, payload)
  },
  SET_YEARS: (state, years) => {
    state.years = years;
  },
  ADD_YEAR: (state, payload) => {
    state.years = Object.assign({}, payload)
  },
 
};

const actions = {

  async storeNewMake({ commit }, payload) {
    await axios
      .post('makes', payload)
      .then((response) => {
        commit("ADD_MAKE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
     
  },

  async loadnewVehicle({ commit }, payload) {
    await axios
    .get(`vehicles/newVehicle/${payload.makemodel_id}`)
    .then((response) => {
      commit("SET_MAKES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  
    
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
