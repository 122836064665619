import axios from "../../api/api.shopcommander";

const state = {
  services: [],
  items: [],
  
 
};

const getters = {
  fetchServices: (state) => {
    return state.services;
  },
 getItems: (state) => {
    return state.items;
  },
  getItemsById: (state) => {
    return state.items;
  },
 getService: (state) => {
    return state.services;
  },
};

const mutations = {
  SET_SERVICES: (state, payload) =>{
    state.services = payload;
  },
  SET_ITEMS: (state, payload) =>{
    state.items = payload;
  },
  ADD_ITEM: (state, payload) => {
    state.items = Object.assign({}, payload);
  },
   
};

const actions = {
  async getItemById({ commit }, payload) {
    await axios
      .get(`items/${payload.item_id}`)
      .then((response) => {
        commit("SET_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewtem({ commit }, payload) {
    await axios
     .post("/items", payload)
     .then((response) => {
      commit("SET_ITEMS", response.data);
     })
     .catch((err) => {
       console.log(err);
     });
 },

 async updateNewItem({ commit }, payload) {
  await axios
    .patch(`items/${payload.item_id}`, payload.data)
    .then((response) => {
      commit("SET_ITEMS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
},
  
 

 
  
 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
