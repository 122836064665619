import axios from "../../api/api.shopcommander";

const state = {
  customerConcerns: []
};

const getters = {
  fetchCustomerConcerns: (state) => {
    return state.customerConcerns;
  },
};

const mutations = {
  SET_CUSTOMER_CONCERNS(state, payload){
    state.customerConcerns = payload;
  },
 
  ADD_CUSTOMER_CONCERN(state, payload){
    state.customerConcerns = Object.assign({}, payload);
  },
  UPDATE_CUSTOMER_CONCERN(state, payload){
    state.customerConcerns = Object.assign({}, payload);
  },
  REMOVE_CUSTOMER_CONCERN: (state, id) => {
    (state.customerConcerns = state.customerConcerns.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchCustomerConcerns({ commit }, payload) {
    await axios
    .get(`customerconcerns?estimate_id=${payload.estimate_id}`)
    .then((response) => {
      commit("SET_CUSTOMER_CONCERNS", response.data);
    })
      .catch((err) => {
        console.log(err);
      });
    
  },
  
  async storeNewCustomerConcern({ commit }, payload) {
     await axios
      .post('customerconcerns', payload)
      .then((response) => {
        commit("ADD_CUSTOMER_CONCERN", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewCustomerConcern({ commit }, payload) {
    await axios
      .patch(`customerconcerns/${payload.customer_concern_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_CUSTOMER_CONCERN", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteCustomerConcern({ commit }, payload) {
    await axios
      .delete(`customerconcerns/${payload.customer_concern_id}`)
      .then((response) => {
        commit("REMOVE_CUSTOMER_CONCERN", response.data);   
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
