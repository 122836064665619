import axios from "../../api/api.shopcommander";

const state = {
  roles: [],
};

const getters = {
  fetchRoles: (state) => {
    return state.roles;
  },
};

const mutations = {
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },

  ADD_ROLE: (state, payload) => {
    state.roles = Object.assign({}, payload);
  },
  UPDATE_ROLE: (state, payload) => {
    state.roles = Object.assign({}, payload);
  },

  REMOVE_ROLE: (state, id) => {
    state.roles = state.roles.filter((item) => item.id !== id);
  },
};

const actions = {
  async fetchRoles({ commit }) {
    await axios
      .get(`roles`)
      .then((response) => {
        commit("SET_ROLES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async searchRoles({ commit }, payload) {
    await axios
      .get(`users/create?draw=${payload.data.draw}&length=${payload.data.length}&search=${payload.data.search}`)
      .then((response) => {
        commit("SET_ROLES", response.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async editRole({ commit }, payload) {
    await axios
    .get(`roles/${payload.role_id}/edit`)
    .then((response) => {
      commit("SET_ROLES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  async storeNewRole({ commit }, payload) {
    await axios
      .post("roles", payload)
      .then((response) => {
        commit("ADD_ROLE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewRole({ commit }, payload) {
    await axios
      .patch(`roles/${payload.role_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_ROLE", response.data);
        console.log(payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteRole({ commit }, payload) {
    await axios
      .delete(`roles/${payload.role_id}`)
      .then((response) => {
        commit("REMOVE_ROLE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
