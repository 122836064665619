import axios from "../../api/api.shopcommander";

const state = {
  image_vaults: []
};

const getters = {
  fetchImageVaults: (state) => {
    return state.image_vaults;
  },
};

const mutations = {
  SET_IMAGE_VAULTS(state, payload){
    state.image_vaults = payload;
  },
 
  ADD_IMAGEVAULT(state, payload){
    state.image_vaults = Object.assign({}, payload);
  },
  UPDATE_IMAGEVAULT(state, payload){
    state.image_vaults = Object.assign({}, payload);
  },
  REMOVE_IMAGE: (state, id) => {
    (state.image_vaults = state.image_vaults.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchImageVaults({ commit }, payload) {
    await axios
    .get(`imagevaults?inspection_item_id=${payload.inspection_item_id}`)
    .then((response) => {
      commit("SET_IMAGE_VAULTS", response.data);
    })
      .catch((err) => {
        console.log(err);
      });
    
  },
  
  async storeNewImageVault({ commit }, payload) {
     await axios
      .post('imagevaults', payload)
      .then((response) => {
        commit("ADD_IMAGEVAULT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewCustomerConcern({ commit }, payload) {
    await axios
      .patch(`customerconcerns/${payload.customer_concern_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_CUSTOMER_CONCERN", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteImageVault({ commit }, payload) {
    await axios
      .delete(`imagevaults/${payload.image_id}`)
      .then((response) => {
        commit("REMOVE_IMAGE", response.data);   
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
