import axios from "../../api/api.shopcommander";

const state = {
  forms: [],
};

const getters = {
  fetchForms: (state) => {
    return state.forms;
  },
};

const mutations = {
  SET_FORMS: (state, forms) => {
    state.forms = forms;
  },

  ADD_FORM: (state, payload) => {
    state.forms = Object.assign({}, payload);
  },
  UPDATE_FORM: (state, payload) => {
    state.forms = Object.assign({}, payload);
  },

  REMOVE_FORM: (state, id) => {
    state.forms = state.forms.filter((item) => item.id !== id);
  },
};

const actions = {
  async fetchForms({ commit }) {
    await axios
      .get(`forms`)
      .then((response) => {
        commit("SET_FORMS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

    async storeNewForm({ commit }, payload) {
    await axios
      .post("forms", payload)
      .then((response) => {
        commit("ADD_FORM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewMenu({ commit }, payload) {
    await axios
      .patch(`forms/${payload.data.form_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_FORM", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteMenu({ commit }, payload) {
    await axios
      .delete(`forms/${payload.id}`)
      .then((response) => {
        commit("REMOVE_FORMS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
