import axios from "../../api/api.shopcommander";
import { getLocalUser } from "../../middleware/auth/authentication";


const user = getLocalUser();

const state = {
  users: [],
  currentUser: user,
  isLoggedIn: false,
  loading: false,
  auth_error: null
};

 const getters = {

  isLoading(state) {
    return state.loading;
},
  isLoggedIn(state) {
    return state.isLoggedIn;
},
currentUser(state) {
    return state.currentUser;
},
authError(state) {
    return state.auth_error;
}
};

const mutations = {
 
  REGISTER_USER:(state, payload) => {
    state.users = Object.assign({}, payload)
   
},
login(state) {
  state.loading = true;
  state.auth_error = null;
},
loginSuccess(state, payload) {
  state.auth_error = null;
  state.isLoggedIn = true;
  state.loading = false;
  state.currentUser = Object.assign({}, payload.user, {
      token: payload.access_token
  });

  localStorage.setItem("user", JSON.stringify(state.currentUser));
},
loginFailed(state, payload) {
  state.loading = false;
  state.auth_error = payload.error;
},
logout(state) {
  localStorage.removeItem("user");
  state.isLoggedIn = false;
  state.currentUser = null;
},
};

const actions = {
 
  async registerUser({commit}, payload) {
    await axios.post('auth/register', payload).then(response => {
        commit('REGISTER_USER', response.data.data);
      }).catch(err => {
        console.log(err);
      });
  },

  loginUser(context) {
    context.commit("login");
}
 };

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};