import axios from "../../api/api.shopcommander";

const state = {
  labors: [],
  laborItems: []
};

const getters = {
  fetchLabors: (state) => {
    return state.labors;
  },

  fetchLaborItems: (state) => {
    return state.laborItems;
  },

};

const mutations = {
  SET_LABORS(state, payload){
    state.labors = payload;
  },

  SET_LABOR_ITEMS(state, payload){
    state.laborItems = payload;
  },
  
  ADD_LABOR: (state, payload) => {
    state.labors = Object.assign({}, payload)
  },
  ADD_LABORITEM: (state, payload) => {
    state.laborItems = Object.assign({}, payload)
  },
  UPDATE_LABOR: (state, payload) => {
    state.labors = Object.assign({}, payload)
  },
  REMOVE_LABOR: (state, id) => {
    (state.labors = state.labors.filter((item) => item.id !== id))
  },
  CHECK_SET_AS_DEFAULT: (state, payload) => {
    state.labors.forEach(labor => {
      labor.default = payload;
    });
   }
}

const actions = {
  async fetchLabors({ commit }) {
    await axios
    .get(`labors`)
    .then((response) => {
      commit("SET_LABORS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async fetchLaborById({ commit }, payload) {
    await axios
    .get(`labors/${payload.labor_id}`)
    .then((response) => {
      commit("SET_LABOR_ITEMS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  async storeNewLabor({ commit }, payload) {
    await axios
      .post('/labors', payload)
      .then((response) => {
        commit("ADD_LABOR", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async updateNewLabor({ commit }, payload) {
    await axios
      .patch(`labors/${payload.labor_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_LABOR", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteLabor({ commit }, payload) {
    await axios
      .delete(`labors/${payload.labor_id}`)
      .then((response) => {
        commit("REMOVE_LABOR", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewLaborItem({ commit }, payload) {
    await axios
      .post('/laborItems', payload)
      .then((response) => {
        commit("ADD_LABORITEM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
