import axios from "../../api/api.shopcommander";

const state = {
  inventory_items: [],
  pricingItems: [],
  laborItems:[],
};

const getters = {
  fetchInventoryItems: (state) => {
    return state.inventory_items;
  },
  fetchInventories: (state) => {
    return state.inventory_items;
  },
  fetchPricingItems: (state) => {
    return state.pricingItems;
  },

  fetchLaborItems: (state) => {
    return state.laborItems;
  },

};

const mutations = {
  SET_INVENTORY_ITEMS: (state, payload) => {
    state.inventory_items = payload;
  },
  SET_PRICING_ITEMS(state, payload){
    state.pricingItems = payload;
  },

  SET_LABOR_ITEMS(state, payload){
    state.laborItems = payload;
  },

  ADD_INVENTORY_ITEMS: (state, payload) => {
    state.inventory_items = Object.assign({}, payload);
  },
  REMOVE_INVENTORY_ITEM: (state, id) => {
    state.inventory_items = state.inventory_items.filter((item) => item.id !== id);
  },
  UPDATE_INVENTORY_ITEM: (state, payload) => {
    state.inventory_items = Object.assign({}, payload)
  },
  
};

const actions = {
  async fetchInventoryItems({ commit }, payload) {
    await axios
      .get(`inventories?item_type=${payload.item_type}`)
      .then((response) => {
        commit("SET_INVENTORY_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async editInventoryItems({ commit }, payload) {
    await axios
      .get(`inventories/${payload.inv_id}/edit`)
      .then((response) => {
        commit("SET_INVENTORY_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async fetchPricingItems({ commit }, payload) {
    await axios
      .get(`pricingItems?pricing_id=${payload.pricing_id}&cost=${payload.cost}`)
      .then((response) => {
        commit("SET_PRICING_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async fetchLaborItems({ commit }, payload) {
    await axios
      .get(`laborItems?labor_id=${payload.labor_id}&hours=${payload.hours}`)
      .then((response) => {
        commit("SET_LABOR_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async getInventoryItems({ commit }, payload) {
    await axios
      .get(`inventories/${payload.item_id}`)
      .then((response) => {
        commit("SET_INVENTORY_ITEMS", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },
  
  async storeNewInventoryItem({ commit }, payload) {
     await axios
      .post("/inventories", payload)
      .then((response) => {
       commit("ADD_INVENTORY_ITEMS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async updateInventoryItem({ commit }, payload) {
    await axios
      .patch(`inventories/${payload.inv_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_INVENTORY_ITEM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteInventoryItem({ commit }, payload) {
    await axios
      .delete(`inventories/${payload.inv_id}`)
      .then((response) => {
        commit("REMOVE_INVENTORY_ITEM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
