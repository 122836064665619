import axios from "../../api/api.shopcommander";

const state = {
  timesheets: [],
 
};

const getters = {
  fetchTimesheets: (state) => {
    return state.timesheets;
  },

};

const mutations = {
  SET_TIMESHEETS(state, payload){
    state.timesheets = payload;
  },
   ADD_TIMESHEET: (state, payload) => {
    state.timesheets = Object.assign({}, payload)
  },
  UPDATE_TIMESHEET: (state, payload) => {
    state.timesheets = Object.assign({}, payload)
  },
  REMOVE_TIMESHEET: (state, id) => {
    (state.timesheets = state.timesheets.filter((item) => item.id !== id))
  },

  RESTORE_TIMESHEET: (state, payload) => {
    state.timesheets = Object.assign({}, payload)
  },
 }

const actions = {
  async fetchTimesheets({ commit }) {
    await axios
    .get(`clocks`)
    .then((response) => {
      commit("SET_TIMESHEETS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async getTimesheets({ commit }) {
    await axios
    .post(`clocks/timesheets`)
    .then((response) => {
      commit("SET_TIMESHEETS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async editTimesheets({ commit }, payload) {
    await axios
    .post(`clocks/showclocks/${payload.user_id}/${payload.entry_date}`)
    .then((response) => {
      commit("SET_TIMESHEETS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async storeNewTimesheet({ commit }, payload) {
    await axios
      .post('/clocks', payload)
      .then((response) => {
        commit("ADD_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewManualClock({ commit }, payload) {
    await axios
      .patch(`clocks/${payload.clock_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewTimesheet({ commit }, payload) {
    await axios
      .patch(`clocks/updateclocks/${payload.user_id}/${payload.entry_date}`, payload.data)
      .then((response) => {
        commit("UPDATE_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteTimesheet({ commit }, payload) {
    await axios
      .delete(`clocks/${payload.clock_id}`)
      .then((response) => {
        commit("REMOVE_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletedClockLogs({ commit }) {
    await axios
    .post(`clocks/logs/deleted`)
    .then((response) => {
      commit("SET_TIMESHEETS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async restoredeletedClockLogs({ commit }, payload) {
    await axios
    .post(`clocks/logs/${payload.clock_id}/restored`)
      .then((response) => {
        commit("RESTORE_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async removedClockLogs({ commit }, payload) {
    await axios
      .post(`clocks/logs/${payload.clock_id}/removed`)
      .then((response) => {
        commit("REMOVE_TIMESHEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
