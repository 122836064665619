import axios from "../../api/api.shopcommander";

const state = {
  inspection_templates: [],
  inspection_categories: [],

};

const getters = {
  fetchInspectionTemplates: (state) => {
    return state.inspection_templates;
  },

  fetchInspectionCategories: (state) => {
    return state.inspection_categories;
  },
  fetchCategoryCount: (state) => {
    return state.inspection_templates;
  }

};

const mutations = {
  SET_INSPECTION_TEMPLATES: (state, inspection_templates) => {
    state.inspection_templates = inspection_templates;
  },
  
  ADD_INSPECTION_TEMPLATE: (state, payload) => {
    state.inspection_templates = Object.assign({}, payload);
  },
  UPDATE_INSPECTION_TEMPLATE: (state, payload) => {
    state.inspection_templates = Object.assign({}, payload);
  },

  REMOVE_INSPECTION_TEMPLATE: (state, id) => {
    state.inspection_templates = state.inspection_templates.filter((item) => item.id !== id);
  },

  SET_INSPECTION_CATEGORIES: (state, inspectcategories) => {
    state.inspection_categories = inspectcategories;
  },
  ADD_INSPECTION_CATEGORY: (state, payload) => {
    state.inspection_categories = Object.assign({}, payload);
  },
  UPDATE_INSPECTION_CATEGORY: (state, payload) => {
    state.inspection_categories = Object.assign({}, payload);
  },

  REMOVE_INSPECTION_CATEGORY: (state, id) => {
    state.inspection_categories = state.inspection_categories.filter((item) => item.id !== id);
  },
 
};

const actions = {
  async fetchInspectionTemplates({ commit }) {
    await axios
      .get(`inspectiontemplates`)
      .then((response) => {
       commit("SET_INSPECTION_TEMPLATES", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  },

   
   async editInspectionTemplates({ commit }, payload) {
   await axios
      .get(`inspectiontemplates/${payload.inspection_id}/edit`)
      .then((response) => {
        commit("SET_INSPECTION_TEMPLATES", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  },
    
  async storeNewInspection({ commit }, payload) {
     await axios
      .post("inspectiontemplates", payload)
      .then((response) => {
        commit("ADD_INSPECTION_TEMPLATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewInspection({ commit }, payload) {
    await axios
      .post(`inspectiontemplates/update`, payload)
      .then((response) => {
        commit("UPDATE_INSPECTION_TEMPLATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInspection({ commit }, payload) {
    await axios
      .delete(`inspectiontemplates/${payload.inspection_id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION_TEMPLATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInspectionGroup({ commit }, payload) {
    await axios
      .delete(`inspectiontemplates/groups/${payload.inspection_cat_id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION_TEMPLATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteInspectionItem({ commit }, payload) {
    await axios
      .delete(`inspectiontemplates/items/${payload.inspection_item_id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION_TEMPLATE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchInspectionCategories({ commit }) {
    await axios
      .get(`inspectioncategories`)
      .then((response) => {
        commit("SET_INSPECTION_CATEGORIES", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewInspectionCategory({ commit }, payload) {
    await axios
      .post("inspectioncategories", payload)
      .then((response) => {
        commit("ADD_INSPECTION", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
