import Vuex from "vuex";
import Vue from "vue";
import page from './modules/page';
import estimate from './modules/estimate';
import authentication from './modules/authentication';
import appointment from './modules/appointment';
import customer from './modules/customer'
import vendor from './modules/vendor';
import vehicle from './modules/vehicle';
import user from './modules/user'
import role from './modules/role'
import permission from './modules/permission';
import fleet from './modules/fleet';
import cannedService from './modules/cannedService';
import service from './modules/service';
import inspection from './modules/inspection';
import inspectionTemplate from './modules/inspectionTemplate';
import inventoryCategory from './modules/inventoryCategory';
import tag from './modules/tag';
import loadVehicle from './modules/loadVehicle';
import menu from './modules/menu';
import form from './modules/form';
import inventory from './modules/inventory';
import purchaseOrder from './modules/purchaseOrder';
import workflow from './modules/workflow';
import customerConcern from './modules/customerConcern';
import technicianConcern from './modules/technicianConcern ';
import pricing from './modules/pricing';
import labor from './modules/labor';
import brand from './modules/brand';
import rate from './modules/rate';
import item from './modules/item';
import imageVault from "./modules/imageVault";
import clock from './modules/clock';
import authorization from "./modules/authorization";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    page,
    authentication,
    estimate,
    service,
    appointment,
    customer,
    vendor,
    vehicle,
    user,
    role,
    permission,
    fleet,
    cannedService,
    inspection,
    inspectionTemplate,
    inventoryCategory,
    tag,
    loadVehicle,
    menu,
    form,
    inventory,
    purchaseOrder,
    pricing,
    labor,
    rate,
    item,
    brand,
    workflow,
    customerConcern,
    technicianConcern,
    imageVault,
    clock,
    authorization,
  },
});

export default store;



