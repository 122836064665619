import axios from "../../api/api.shopcommander";

const state = {
  pricings: [],
  pricingItems: []
};

const getters = {
  fetchPricings: (state) => {
    return state.pricings;
  },

  fetchPricingItems: (state) => {
    return state.pricingItems;
  },

};

const mutations = {
  SET_PRICINGS(state, payload){
    state.pricings = payload;
  },

  SET_PRICING_ITEMS(state, payload){
    state.pricingItems = payload;
  },
  
  ADD_PRICING: (state, payload) => {
    state.pricings = Object.assign({}, payload)
  },
  ADD_PRICINGITEM: (state, payload) => {
    state.pricingItems = Object.assign({}, payload)
  },
  UPDATE_PRICING: (state, payload) => {
    state.pricings = Object.assign({}, payload)
  },
  REMOVE_PRICING: (state, id) => {
    (state.pricings = state.pricings.filter((item) => item.id !== id))
  },
  CHECK_SET_AS_DEFAULT: (state, payload) => {
    state.pricings.forEach(pricing => {
      pricing.default = payload;
    });
   }
}

const actions = {
  async fetchPricings({ commit }) {
    await axios
    .get(`pricings`)
    .then((response) => {
      commit("SET_PRICINGS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async fetchPricingById({ commit }, payload) {
    await axios
    .get(`pricings/${payload.pricing_id}`)
    .then((response) => {
      commit("SET_PRICING_ITEMS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  async storeNewPricing({ commit }, payload) {
    await axios
      .post('/pricings', payload)
      .then((response) => {
        commit("ADD_PRICING", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  
  async updateNewPricing({ commit }, payload) {
    await axios
      .patch(`pricings/${payload.pricing_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_PRICING", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletePricing({ commit }, payload) {
    await axios
      .delete(`pricings/${payload.pricing_id}`)
      .then((response) => {
        commit("REMOVE_PRICING", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewPricingItem({ commit }, payload) {
    await axios
      .post('/pricingItems', payload)
      .then((response) => {
        commit("ADD_PRICINGITEM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
