import axios from "../../api/api.shopcommander";

const state = {
  purchaseOrders: [],
 
};

const getters = {
  fetchPurchaseOrders: (state) => {
    return state.purchaseOrders;
  },
};

const mutations = {
  SET_PURCHASE_ORDERS(state, payload){
    state.purchaseOrders = payload;
  },
    
  ADD_PURCHASE_ORDER: (state, payload) => {
    state.purchaseOrders = Object.assign({}, payload)
  },
 
  UPDATE_PURCHASE_ORDER: (state, payload) => {
    state.purchaseOrders = Object.assign({}, payload)
  },
  REMOVE_PURCHASE_ORDER: (state, id) => {
    (state.purchaseOrders = state.purchaseOrders.filter((item) => item.id !== id))
  },
 
}

const actions = {
  async fetchPurchaseOrders({ commit }, payload) {
    await axios
    .get(`purchaseorders?status=${payload.status}`)
    .then((response) => {
      commit("SET_PURCHASE_ORDERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async editPurchaseOrder({ commit }, payload) {
    await axios
    .get(`purchaseorders/${payload.purchase_id}/edit`)
    .then((response) => {
      commit("SET_PURCHASE_ORDERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
    
  async showPurchaseOrder({ commit }, payload) {
    await axios
    .get(`purchaseorders/${payload.purchase_id}`)
    .then((response) => {
      commit("SET_PURCHASE_ORDERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async storeNewPuchaseOrder({ commit }, payload) {
    await axios
      .post('/purchaseorders', payload)
      .then((response) => {
        commit("ADD_PURCHASE_ORDER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async updateNewPuchaseOrder({ commit }, payload) {
    await axios
      .patch(`purchaseorders/${payload.purchase_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_PURCHASE_ORDER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletePuchaseOrder({ commit }, payload) {
    await axios
      .delete(`purchaseorders/${payload.purchase_id}`)
      .then((response) => {
        commit("REMOVE_LABOR", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
