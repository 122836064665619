import axios from "../../api/api.shopcommander";

const state = {
   tags: []
};

const getters = {
  fetchTags: (state) => {
    return state.tags;
  },
};

const mutations = {
  SET_TAGS(state, payload){
    state.tags = payload;
  },
  ADD_TAG(state, payload){
    state.tags = Object.assign({}, payload);
  },
  UPDATE_TAG(state, payload){
    state.tags = Object.assign({}, payload);
  }
}

const actions = {
  async fetchTags({ commit }, payload) {
    await axios
    .get('tags')
    .then((response) => {
      commit("SET_TAGS", response.data);
    })
      .catch((err) => {
        console.log(err);
      });
    
  },
  
  async storeNewTag({ commit }, payload) {
     await axios
      .post('tags', payload)
      .then((response) => {
        commit("ADD_TAG", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewTag({ commit }, payload) {
    await axios
      .put(`fleets/${payload.fleet_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_FLEET", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteTag({ commit }, payload) {
    await axios
      .delete(`fleets/${payload.fleet_id}`)
      .then((response) => {
          
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
