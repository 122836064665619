import axios from "../../api/api.shopcommander";

const state = {
  customers: []
};

const getters = {
  fetchCustomers: (state) => {
    return state.customers;
  },

};

const mutations = {
  SET_CUSTOMERS(state, customers){
    state.customers = customers;
  },
  
  ADD_CUSTOMER: (state, payload) => {
    state.customers = Object.assign({}, payload)
  },
  UPDATE_CUSTOMER: (state, payload) => {
    state.customers = Object.assign({}, payload)
  },
  REMOVE_CUSTOMER: (state, id) => {
    (state.customers = state.customers.filter((item) => item.id !== id))
  },
  RESTORE_CUSTOMER: (state, payload) => {
    state.customers = Object.assign({}, payload)
  },
}

const actions = {
  async fetchCustomers({ commit }) {
    await axios
    .get(`customers`)
    .then((response) => {
      commit("SET_CUSTOMERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  
  async editCustomer({ commit }, payload) {
    await axios
    .get(`customers/${payload.customer_id}/edit`)
    .then((response) => {
      commit("SET_CUSTOMERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async storeNewCustomer({ commit }, payload) {
    await axios
      .post('/customers', payload)
      .then((response) => {
        commit("ADD_CUSTOMER", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewCustomer({ commit }, payload) {
    await axios
      .patch(`customers/${payload.customer_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_CUSTOMER", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteCustomer({ commit }, payload) {
    await axios
      .delete(`customers/${payload.customer_id}`)
      .then((response) => {
        commit("REMOVE_CUSTOMER", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletedCustomerLogs({ commit }) {
    await axios
    .post(`customers/logs/deleted`)
    .then((response) => {
      commit("SET_CUSTOMERS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async restoredeletedCustomerLogs({ commit }, payload) {
    await axios
    .post(`customers/logs/${payload.customer_id}/restored`)
      .then((response) => {
        commit("RESTORE_CUSTOMER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
