<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="280"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/dashboard" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">SHOPCommander</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list v-if="currentUser.role_id == 3" expand shaped class="vertical-nav-menu-items pr-5">
      <div v-for="(authorization, index1) in authorizations" :key="index1">
        <div v-for="(menu, index2) in authorization.usermenus" :key="index2">
          <nav-menu-link
            v-if="!menu.submenu"
            :title="menu.name"
            :icon="menu.icon"
            :to="{ name: menu.link }"
          ></nav-menu-link>
          <nav-menu-group v-else :title="menu.name" :icon="menu.icon">
            <nav-menu-link
              v-for="(submenu, index3) in menu.user_submenus"
              :key="index3"
              :title="submenu.name"
              :icon="submenu.icon"
              :to="{ name: submenu.link }"
            ></nav-menu-link>
          </nav-menu-group>
        </div>
      </div>
    </v-list>
    <v-list v-else expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-group title="Workflow" :icon="icons.mdiLayersTripleOutline">
        <nav-menu-link
          title="Workflow List"
          :icon="icons.mdiClipboardListOutline"
          :to="{ name: 'workflow' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Appointments"
          :icon="icons.mdiCalendarArrowLeft"
          :to="{ name: 'appointment' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link title="Calendar" :to="{ name: 'calendar' }" :icon="icons.mdiCalendarCheck"></nav-menu-link>
      <nav-menu-link title="Inventory" :to="{ name: 'inventory' }" :icon="icons.mdiArchiveArrowDown"></nav-menu-link>
      <nav-menu-link title="Purchasing" :to="{ name: 'purchaselist' }" :icon="icons.mdiTruckDelivery"></nav-menu-link>
      <nav-menu-link title="Clock" :to="{ name: 'clock' }" :icon="icons.mdiAlarm"></nav-menu-link>

      <nav-menu-group title="Lists" :icon="icons.mdiViewList">
        <nav-menu-link title="Customers" :icon="icons.mdiAccountTie" :to="{ name: 'customer' }"></nav-menu-link>
        <nav-menu-link title="Vehicles" :icon="icons.mdiCar" :to="{ name: 'vehicle' }"></nav-menu-link>
        <nav-menu-link title="Vendors" :icon="icons.mdiAccountMultiple" :to="{ name: 'vendor' }"></nav-menu-link>
        <nav-menu-link title="Fleets" :icon="icons.mdiBusMultiple" :to="{ name: 'fleet' }"></nav-menu-link>
        <nav-menu-link
          title="Inspection Categories"
          :icon="icons.mdiClipboardCheck"
          :to="{ name: 'inspectioncategorylist' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Inspection Templates"
          :icon="icons.mdiCheckAll"
          :to="{ name: 'inspectiontemplate' }"
        ></nav-menu-link>
        <nav-menu-link title="Canned Services" :icon="icons.mdiToolbox" :to="{ name: 'cannedservice' }"></nav-menu-link>
        <nav-menu-link
          title="Inventory Categories"
          :icon="icons.mdiChartBoxOutline"
          :to="{ name: 'inventorycategory' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Settings" :icon="icons.mdiCogOutline">
        <nav-menu-link title="Pricing Matrix" :icon="icons.mdiMatrix" :to="{ name: 'pricingMatrix' }"></nav-menu-link>
        <nav-menu-link title="Labor Matrix" :icon="icons.mdiMatrix" :to="{ name: 'laborMatrix' }"></nav-menu-link>
        <nav-menu-link title="Rates" :icon="icons.mdiPercentOutline" :to="{ name: 'rate' }"></nav-menu-link>
        <nav-menu-link title="Users" :icon="icons.mdiAccountCircle" :to="{ name: 'user' }"></nav-menu-link>
        <nav-menu-link title="Roles" :icon="icons.mdiAccountCheck" :to="{ name: 'role' }"></nav-menu-link>
        <nav-menu-link title="Brands" :icon="icons.mdiCarBrakeAbs" :to="{ name: 'brand' }"></nav-menu-link>
        <nav-menu-link
          title="Permissions"
          :icon="icons.mdiAccountKeyOutline"
          :to="{ name: 'permission-list' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Logs" :icon="icons.mdiBadgeAccount">
        <nav-menu-link title="User Logs" :icon="icons.mdiCogClockwise" :to="{ name: 'logUser' }"></nav-menu-link>
        <nav-menu-link
          title="Customer Logs"
          :icon="icons.mdiAccountHardHat"
          :to="{ name: 'logCustomer' }"
        ></nav-menu-link>
        <nav-menu-link title="Vehicle Logs" :icon="icons.mdiCar" :to="{ name: 'logVehicle' }"></nav-menu-link>
        <nav-menu-link title="Clock Logs" :icon="icons.mdiClockCheckOutline" :to="{ name: 'logClock' }"></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiLayersTripleOutline,
  mdiCalendarCheck,
  mdiArchiveArrowDown,
  mdiTruckDelivery,
  mdiAlarm,
  mdiViewList,
  mdiAccountTie,
  mdiCar,
  mdiAccountMultiple,
  mdiClipboardListOutline,
  mdiCalendarArrowLeft,
  mdiBusMultiple,
  mdiClipboardCheck,
  mdiCheckAll,
  mdiToolbox,
  mdiCogOutline,
  mdiChartBoxOutline,
  mdiMatrix,
  mdiPercentOutline,
  mdiAccountGroup,
  mdiAccountCircle,
  mdiAccountCheck,
  mdiAccountKeyOutline,
  mdiBadgeAccount,
  mdiCogClockwise,
  mdiAccountHardHat,
  mdiClockCheckOutline,
  mdiCarBrakeAbs,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },

  created: function() {
    this.getAuthorizations()
    this.getMenus()
  },
  data() {
    return {
      authorizations: [],
      menus: [],

      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiLayersTripleOutline,
        mdiCalendarCheck,
        mdiArchiveArrowDown,
        mdiTruckDelivery,
        mdiAlarm,
        mdiViewList,
        mdiAccountTie,
        mdiCar,
        mdiAccountMultiple,
        mdiClipboardListOutline,
        mdiCalendarArrowLeft,
        mdiBusMultiple,
        mdiClipboardCheck,
        mdiCheckAll,
        mdiToolbox,
        mdiCogOutline,
        mdiChartBoxOutline,
        mdiMatrix,
        mdiPercentOutline,
        mdiAccountGroup,
        mdiAccountCircle,
        mdiAccountCheck,
        mdiAccountKeyOutline,
        mdiBadgeAccount,
        mdiCogClockwise,
        mdiAccountHardHat,
        mdiClockCheckOutline,
        mdiCarBrakeAbs,
      },
    }
  },
  methods: {
    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchAuthorizations', {
          user_id: this.currentUser.user_id,
          menu_id: '',
        })
        .then(response => {
          this.authorizations = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    getMenus: function() {
      this.$store
        .dispatch('menu/fetchMenus')
        .then(response => {
          this.menus = this.fetchMenus
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchAuthorizations: 'authorization/fetchAuthorizations',
      fetchMenus: 'menu/fetchMenus',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
