import axios from "../../api/api.shopcommander";


const state = {
  estimates: [],
  services: [],
  items: [],
  inventoryItems: [],
 
};

const getters = {
  fetchServices: (state) => {
    return state.services;
  },

  fetchInventory: (state) => {
    return state.inventoryItems;
  },
 
};

const mutations = {
  SET_SERVICES: (state, payload) =>{
    state.services = payload;
  },
  SET_ESTIMATES: (state, payload) =>{
    state.estimates = payload;
  },
  
  SET_ITEMS: (state, payload) =>{
    state.items = payload;
  },

  SET_INVENTORY: (state, payload) =>{
    state.inventoryItems = payload;
  },

  ADD_SERVICE: (state, payload) => {
    state.services = Object.assign({}, payload);
  },
  UPDATE_SERVICE: (state, payload) => {
    state.services = Object.assign({}, payload);
  },
  REMOVE_SERVICE: (state, id) => {
    state.services = state.services.filter((item) => item.id !== id);
  },

  REMOVE_ITEM: (state, id) => {
    state.items = state.items.filter((item) => item.id !== id);
  },
};

const actions = {
  
  async fetchServices({ commit }, payload) {
    await axios
    .get(`services/${payload.estimate_id}/edit`)
    .then((response) => {
      commit("SET_SERVICES", response.data);
    })
      .catch((err) => {
        console.log(err);
      });
  
  },
  async fetchInventories({ commit }) {
    await axios
    .get(`services`)
    .then((response) => {
      commit("SET_INVENTORY", response.data.data);
    })
      .catch((err) => {
        console.log(err);
      });
    },

  async storeNewService({ commit }, payload) {
    axios.post("services", payload)
      .then((response) => {
        commit("ADD_SERVICE", response.data);
       })
      .catch((err) => {
        console.log(err);
      
    });
    
  },
  async updateNewService({ commit }, payload) {
    await axios
      .patch(`services/${payload.service_id}`, payload)
      .then((response) => {
        commit("UPDATE_SERVICE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewServiceItems({ commit }, payload) {
    await axios
    .patch(`services/serviceItems/${payload.item_id}`, payload.data)
      .then((response) => {
        commit("SET_SERVICES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteService({ commit }, payload) {
    await axios
      .delete(`services/${payload.service_id}`)
      .then((response) => {
        commit("REMOVE_SERVICE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteServiceItem({ commit }, payload) {
    await axios
      .delete(`items/${payload.item_id}`)
      .then((response) => {
        commit("REMOVE_ITEM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
