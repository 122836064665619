import axios from "../../api/api.shopcommander";

const state = {
 cannedServices: [],
 canned_services_categories: [],
 searchCannedServices: [],
 
};

const getters = {

  fetchCannedServices: (state) => {
    return state.cannedServices;
  },

  fetchCannedServicesCategories: (state) => {
    return state.canned_services_categories;
  },
  fetchSearchCannedServices: (state) => {
    return state.searchCannedServices;
  },

  fetchSearchCannedServicesAll: (state) => {
    return state.searchCannedServices;
  },
  fetchAddCannedServiceItemNo: (state) => {
    return state.cannedServices;
  },
};

const mutations = {

  SET_CANNED_SERVICES(state, payload){
    state.cannedServices = payload;
  },

  ADD_CANNED_SERVICES(state, payload){
    state.cannedServices = Object.assign({}, payload);
  },

  UPDATE_CANNED_SERVICES(state, payload){
    state.cannedServices = Object.assign({}, payload);
  },

  SET_CANNED_SERVICES_CATEGORIES(state, payload){
    state.canned_services_categories = payload;
  },
  SET_SEARCH_CANNED_SERVICES(state, payload){
    state.searchCannedServices = payload;
  },

  ADD_CANNED_SERVICES_CATEGORIES(state, payload){
    state.canned_services_categories = Object.assign({}, payload);
  },
  UPDATE_CANNED_SERVICES_CATEGORIES(state, payload){
    state.canned_services_categories = Object.assign({}, payload);
  },
  REMOVE_CANNED_SERVICES: (state, id) => {
    (state.cannedServices = state.cannedServices.filter((item) => item.id !== id))
  },
  REMOVE_CANNED_SERVICES_CATEGORIES: (state, id) => {
    (state.canned_services_categories = state.canned_services_categories.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchCannedServicesCategories({ commit }, payload) {
    await axios
      .get(`cannedservicecategories`)
      .then((response) => {
        commit("SET_CANNED_SERVICES_CATEGORIES", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },
  async storeNewCannedServicesCategory({ commit }, payload) {
    await axios
      .post('/cannedservicecategories', payload)
      .then((response) => {
        commit("ADD_CANNED_SERVICES_CATEGORIES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewCannedServicesCategory({ commit }, payload) {
    await axios
      .patch(`cannedservicecategories/${payload.cann_service_cat_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_CANNED_SERVICES_CATEGORIES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  

  async deleteCannedService({ commit }, payload) {
    await axios
      .delete(`cannedservices/${payload.service_id}`)
      .then((response) => {
        commit("REMOVE_CANNED_SERVICES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteCannedServiceCategories({ commit }, payload) {
    await axios
      .delete(`cannedservicecategories/${payload.cann_service_cat_id}`)
      .then((response) => {
        commit("REMOVE_CANNED_SERVICES_CATEGORIES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchCannedServices({ commit }, payload) {
    await axios
      .get(`cannedservices?canned_id=${payload.canned_id}`)
      .then((response) => {
        commit("SET_CANNED_SERVICES", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async editCannedServices({ commit }, payload) {
    await axios
    .get(`cannedservices/${payload.service_id}/edit`)
    .then((response) => {
      commit("SET_CANNED_SERVICES", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async storeNewCannedServices({ commit }, payload) {
    await axios
      .post('/cannedservices', payload)
      .then((response) => {
        commit("ADD_CANNED_SERVICES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewCannedServices({ commit }, payload) {
    await axios
    .patch(`cannedservices/${payload.service_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_CANNED_SERVICES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewCannedServiceItems({ commit }, payload) {
    await axios
    .patch(`cannedservices/updatecannedserviceitems/${payload.item_id}`, payload.data)
      .then((response) => {
        commit("SET_CANNED_SERVICES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

 
  async searchCannedServices({ commit }, canned_id) {
    await axios
      .post(`cannedservices/search?canned_id=${canned_id}`)
      .then((response) => {
        commit("SET_SEARCH_CANNED_SERVICES", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async searchCannedServicesAll({ commit }, canned_id) {
    await axios
      .post(`cannedservices/searchall`)
      .then((response) => {
        commit("SET_SEARCH_CANNED_SERVICES", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async addservice({ commit }, payload) {
    await axios
      .post(`cannedservices/addservice/${payload.service_id}`, payload.data)
      .then((response) => {
        commit("SET_CANNED_SERVICES", response.data.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },

  async addCannedServiceItem({ commit }, payload) {
    await axios
      .post(`cannedservices/createnewid`)
      .then((response) => {
        commit("SET_CANNED_SERVICES", response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  },
 

};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
