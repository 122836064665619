import axios from "../../api/api.shopcommander";

const state = {
  inventory_categories: []
};

const getters = {
  fetchInventoryCategories: (state) => {
    return state.inventory_categories;
  },
  
};

const mutations = {
  SET_INVENTORY_CATEGORIES(state, inventory_categories){
    state.inventory_categories = inventory_categories;
  },
  ADD_INVENTORY_CATEGORIES(state, payload){
    state.inventory_categories = Object.assign({}, payload);
  },
  UPDATE_INVENTORY_CATEGORIES(state, payload){
    state.inventory_categories = Object.assign({}, payload);
  },
}

const actions = {
  async fetchInventoryCategories({ commit }, payload) {
    await axios
      .get(`inventorycategories`)
      .then((response) => {
       commit("SET_INVENTORY_CATEGORIES", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  
  },
  

  async storeNewInventoryCategories({ commit }, payload) {
    await axios
      .post('/inventorycategories', payload)
      .then((response) => {
        commit("ADD_INVENTORY_CATEGORIES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewInventoryCategories({ commit }, payload) {
    await axios
      .patch(`inventorycategories/${payload.inv_category_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_INVENTORY_CATEGORIES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInventoryCategories({ commit }, payload) {
    await axios
      .delete(`inventorycategories/${payload.inv_category_id}`)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
