import axios from "../../api/api.shopcommander";

export function initialize(store, router) {
    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth)) {
          // this route requires auth, check if logged in
          // if not, redirect to login page.
          if (!store.getters['authentication/currentUser']) {
            next({ name: 'signin' })
         }
          else if (to.path == "/" && store.getters['authentication/currentUser']) {
            next("/")
            
          }
          else {
            next() 
          }
        } else {
          next() 
        }
      });
   

    axios.interceptors.response.use(null, error => {
        if (error.response.status == 401) {
            store.commit(`authentication/logout`);
            console.log(error);
        }

        return Promise.reject(error);
    });

    if (store.getters['authentication/currentUser']) {
        setAuthorization(store.getters['authentication/currentUser'].token);
    }
}

export function setAuthorization(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
