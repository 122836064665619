import axios from "../../api/api.shopcommander";

const state = {
  workflows: [],
  estimates:[],
 
};

const getters = {
  fetchWorkflows: (state) => {
    return state.workflows;
  },
  
  fetchWorkfowEstimates: (state) => {
    return state.estimates;
  },
};

const mutations = {
  SET_WORKFLOWS: (state, payload) => {
    state.workflows = payload;
  },
  SET_ESTIMATES: (state, payload) => {
    state.estimates = payload;
  },

   ADD_WORKFLOW: (state, payload) => {
    state.workflows = Object.assign({}, payload)
  },
  UPDATE_WORKFLOW: (state, payload) => {
    state.workflows = Object.assign({}, payload)
  },

  UPDATE_WORKFLOW_ESTIMATE: (state, payload) => {
    state.estimates = Object.assign({}, payload);
   },

  REMOVE_WORKFLOW: (state, id) => {
    (state.workflows = state.workflows.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchWorkflows({ commit }, payload) {
    await axios
    .get(`workflows`)
    .then((response) => {
      commit("SET_WORKFLOWS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async storeWorkflowStatus({ commit }, payload) {
    await axios
      .post('/workflows', payload)
      .then((response) => {
        commit("ADD_WORKFLOW", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateWorkflowStatus({ commit }, payload) {
    await axios
      .patch(`workflows/${payload.workflow_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_WORKFLOW", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

   async updateWorkflowEstimateStatus({ commit }, payload) {
    await axios
    .patch(`workflows/updateworkflowstatus/${payload.estimate_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_WORKFLOW_ESTIMATE", response.data);
        
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteWorkflowStatus({ commit }, payload) {
    await axios
      .delete(`workflows/${payload.workflow_id}`)
      .then((response) => {
        commit("REMOVE_WORKFLOW", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
