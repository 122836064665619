import axios from "../../api/api.shopcommander";

const state = {
  menus: [],
  submenus: [],
};

const getters = {
  fetchMenus: (state) => {
    return state.menus;
  },

  fetchSubMenus: (state) => {
    return state.submenus;
  },
  fetchPagePermissions: (state) => {
    return state.menus;
  },
};

const mutations = {
  SET_MENUS: (state, menus) => {
    state.menus = menus;
  },
  SET_SUBMENUS: (state, submenus) => {
    state.submenus = submenus;
  },
  
};

const actions = {
  async fetchPagePermissions({ commit }, payload) {
    await axios
      .get(`pages?user_id=${payload.user_id}`)
      .then((response) => {
        commit("SET_MENUS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
