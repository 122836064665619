import axios from "../../api/api.shopcommander";

const state = {
  fleets: []
};

const getters = {
  fetchFleets: (state) => {
    return state.fleets;
  },
};

const mutations = {
  SET_FLEETS(state, fleets){
    state.fleets = fleets;
  },
 
  ADD_FLEET(state, payload){
    state.fleets = Object.assign({}, payload);
  },
  UPDATE_FLEET(state, payload){
    state.fleets = Object.assign({}, payload);
  }
}

const actions = {
  async fetchFleets({ commit }, payload) {
    await axios
    .get(`fleets`)
      .then((response) => {
        commit("SET_FLEETS", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
    
  },
 
  async storeNewFleet({ commit }, payload) {
     await axios
      .post('fleets', payload)
      .then((response) => {
        commit("ADD_FLEET", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewFleet({ commit }, payload) {
    await axios
      .put(`fleets/${payload.fleet_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_FLEET", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteFleet({ commit }, payload) {
    await axios
      .delete(`fleets/${payload.fleet_id}`)
      .then((response) => {
          
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
