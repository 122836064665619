import axios from "../../api/api.shopcommander";

const state = {
  brands: []
};

const getters = {
  fetchBrands: (state) => {
    return state.brands;
  },

};

const mutations = {
  SET_BRANDS(state, brands){
    state.brands = brands;
  },
  
  ADD_BRAND: (state, payload) => {
    state.brands = Object.assign({}, payload)
  },
  UPDATE_BRAND: (state, payload) => {
    state.brands = Object.assign({}, payload)
  },
  REMOVE_BRAND: (state, id) => {
    (state.brands = state.brands.filter((item) => item.id !== id))
  }
}

const actions = {
  async fetchBrands({ commit }) {
    await axios
    .get(`brands`)
    .then((response) => {
      commit("SET_BRANDS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
    
  async storeNewBrand({ commit }, payload) {
    await axios
      .post('/brands', payload)
      .then((response) => {
        commit("ADD_BRAND", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewBrand({ commit }, payload) {
    await axios
      .patch(`brands/${payload.brand_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_BRAND", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteBrand({ commit }, payload) {
    await axios
      .delete(`brands/${payload.brand_id}`)
      .then((response) => {
        commit("REMOVE_BRAND", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
