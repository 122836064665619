// import { resolve } from "core-js/fn/promise";
import axios from "../../api/api.shopcommander";

const state = {
  inspections: [],
  inspection_categories: [],
  image_vaults: []
};

const getters = {
  fetchInspections: (state) => {
    return state.inspections;
  },

  fetchInspectionCategories: (state) => {
    return state.inspection_categories;
  },
  
  fetchImageVault: (state) => {
    return state.image_vaults;
  },
};

const mutations = {
  SET_INSPECTIONS: (state, inspections) => {
    state.inspections = inspections;
  },
  
  ADD_INSPECTION: (state, payload) => {
    state.inspections = Object.assign({}, payload);
  },
  UPDATE_INSPECTION: (state, payload) => {
    state.inspections = Object.assign({}, payload);
  },

  REMOVE_INSPECTION: (state, id) => {
    state.inspections = state.inspections.filter((item) => item.id !== id);
  },

  SET_INSPECTION_CATEGORIES: (state, inspectcategories) => {
    state.inspection_categories = inspectcategories;
  },
  ADD_INSPECTION_CATEGORY: (state, payload) => {
    state.inspection_categories = Object.assign({}, payload);
  },
  UPDATE_INSPECTION_CATEGORY: (state, payload) => {
    state.inspection_categories = Object.assign({}, payload);
  },

  REMOVE_INSPECTION_CATEGORY: (state, id) => {
    state.inspection_categories = state.inspection_categories.filter((item) => item.id !== id);
  },

  SET_IMAGE_VAULT: (state, image_vaults) => {
    state.image_vaults = image_vaults;
  },

  ADD_IMAGE_VAULT: (state, payload) => {
    state.image_vaults = Object.assign({}, payload);
  },

  UPDATE_IMAGE_VAULT(state, payload){
    state.image_vaults = Object.assign({}, payload);
  }
};

const actions = {
  async fetchInspections({ commit }) {
    await axios
      .get(`inspections`)
      .then((response) => {
        commit("SET_INSPECTIONS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async getInspections({ commit }, payload) {
    return new Promise((resolve, reject) => {
    axios
      .get(`inspections/${payload.estimate_id}/edit`)
      .then((response) => {
        commit("SET_INSPECTIONS", response.data.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
    })
  },

  async storeNewInspection({ commit }, payload) {
    await axios
      .post("inspections", payload)
      .then((response) => {
        commit("ADD_INSPECTION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewInspection({ commit }, payload) {
    await axios
      .post(`inspections/update`, payload)
      .then((response) => {
        commit("UPDATE_INSPECTION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async addOrCreateInspection({ commit }, payload) {
    return new Promise((resolve, reject) => {
    axios
      .post(`inspections/create/${payload.inspection_id}`, payload)
      .then((response) => {
        commit("ADD_INSPECTION", response.data.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
    })
  },

  async deleteInspection({ commit }, payload) {
    await axios
      .delete(`inspections/${payload.inspection_id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInspectionItem({ commit }, payload) {
    await axios
      .delete(`inspections/items/${payload.id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async fetchInspectionCategories({ commit }) {
    await axios
      .get(`inspectioncategories`)
      .then((response) => {
        commit("SET_INSPECTION_CATEGORIES", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewInspectionCategory({ commit }, payload) {
    await axios
      .post("inspectioncategories", payload)
      .then((response) => {
        commit("ADD_INSPECTION_CATEGORY", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateNewInspectionCategory({ commit }, payload) {
    await axios
    .patch(`inspectioncategories/${payload.inspection_cat_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_INSPECTION_CATEGORY", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInspectionCategories({ commit }, payload) {
    await axios
      .delete(`inspectioncategories/${payload.inspection_cat_id}`)
      .then((response) => {
        commit("REMOVE_INSPECTION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchImageVault({ commit }, payload) {
    await axios
      .get(`imagevaults/${payload.inspection_cat_id}`)
      .then((response) => {
        commit("SET_IMAGE_VAULT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewImageVault({ commit }, payload) {
    await axios
      .post("imagevaults", payload)
      .then((response) => {
        commit("ADD_IMAGE_VAULT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewImageVault({ commit }, payload) {
    await axios
      .put(`imagevaults/${payload.image_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_IMAGE_VAULT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteInspectionImage({ commit }, payload) {
    await axios
      .delete(`imagevaults/${payload.image_id}`)
      .then((response) => {
        commit("SET_IMAGE_VAULT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
