import axios from "../../api/api.shopcommander";

const state = {
  permissions: [],
 
};

const getters = {
  fetchPermissions: (state) => {
    return state.permissions;
  },
 
};

const mutations = {
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },

  ADD_PERMISSION: (state, payload) => {
    state.permissions = Object.assign({}, payload);
  },
  UPDATE_PERMISSION: (state, payload) => {
    state.permissions = Object.assign({}, payload);
  },

  REMOVE_PERMISSION: (state, id) => {
    state.permissions = state.roles.filter((item) => item.id !== id);
  },
 
  CHECK_CREATE: (state, payload) => {
    state.permissions.forEach(permission => {
      permission.create = payload;
    });
  },
  CHECK_READ: (state, payload) => {
    state.permissions.forEach(permission => {
      permission.read = payload;
    });
  },
  CHECK_UPDATE: (state, payload) => {
    state.permissions.forEach(permission => {
      permission.update = payload;
    });
  },
  CHECK_DELETE: (state, payload) => {
    state.permissions.forEach(permission => {
      permission.delete = payload;
    });
  },
   
};

const actions = {
  async fetchPermissions({ commit }) {
    await axios
      .get(`permissions`)
      .then((response) => {
        commit("SET_PERMISSIONS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getPermissions({ commit },  payload) {
    await axios
     .get(`permissions/${payload.permission_id}/edit`)
      .then((response) => {
        commit("SET_PERMISSIONS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async storeNewPermission({ commit }, payload) {
    await axios
      .post("permissions", payload)
      .then((response) => {
        commit("ADD_PERMISSION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewPermission({ commit }, payload) {
    await axios
      .patch(`permissions/${payload.data.permission_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_PERMISSION", response.data);
        console.log(payload)
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deletePermission({ commit }, payload) {
    await axios
      .delete(`permissions/${payload.id}`)
      .then((response) => {
        commit("REMOVE_PERMISSION", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
