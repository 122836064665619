import axios from "../../api/api.shopcommander";

const state = {
  authorizations: [],
 
};

const getters = {
  fetchAuthorizations: (state) => {
    return state.authorizations;
  },
};

const mutations = {
  SET_AUTHORIZATIONS: (state, payload) => {
    state.authorizations = payload;
  },
};

const actions = {
  async fetchAuthorizations({ commit }, payload) {
    await axios
      .get(`authorizations?user_id=${payload.user_id}&menu_id=${payload.menu_id}`)
      .then((response) => {
        commit("SET_AUTHORIZATIONS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchModules({ commit }, payload) {
    await axios
      .post(`authorizations/modules?user_id=${payload.user_id}&menu_id=${payload.menu_id}&submenu_id=${payload.submenu_id}`)
      .then((response) => {
        commit("SET_AUTHORIZATIONS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  
 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
