import axios from "../../api/api.shopcommander";

const state = {
  menus: [],
  submenus: []
};

const getters = {
  fetchMenus: (state) => {
    return state.menus;
  },

  fetchSubMenus: (state) => {
    return state.submenus;
  },
};

const mutations = {
  SET_MENUS: (state, menus) => {
    state.menus = menus;
  },

  SET_SUBMENUS: (state, submenus) => {
    state.submenus = submenus;
  },

  ADD_MENU: (state, payload) => {
    state.menus = Object.assign({}, payload);
  },
  UPDATE_MENU: (state, payload) => {
    state.menus = Object.assign({}, payload);
  },

  REMOVE_MENU: (state, id) => {
    state.menus = state.menus.filter((item) => item.id !== id);
  },
  REMOVE_SUBMENU: (state, id) => {
    state.submenus = state.submenus.filter((item) => item.id !== id);
  },
};

const actions = {
  async fetchMenus({ commit }) {
    await axios
      .get(`menus`)
      .then((response) => {
        commit("SET_MENUS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async fetchSubMenus({ commit }, payload) {
    await axios
      .post(`menus/submenu?menu_id=${payload.menu_id}`)
      .then((response) => {
        commit("SET_SUBMENUS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
  async storeNewMenu({ commit }, payload) {
    await axios
      .post("menus", payload)
      .then((response) => {
        commit("ADD_MENU", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewMenu({ commit }, payload) {
    await axios
      .patch(`menus/${payload.data.menu_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_MENU", response.data);
       
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteMenu({ commit }, payload) {
    await axios
      .delete(`menus/${payload.id}`)
      .then((response) => {
        commit("REMOVE_MENU", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteSubMenu({ commit }, payload) {
    await axios
      .delete(`menus/submenu/${payload.id}`)
      .then((response) => {
        commit("REMOVE_SUBMENU", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
