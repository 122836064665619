import axios from "../../api/api.shopcommander";

const state = {
  vendors: []
};

const getters = {
  fetchVendors: (state) => {
    return state.vendors;
  },
};

const mutations = {
  SET_VENDORS(state, vendors){
    state.vendors = vendors;
  },
  ADD_VENDOR: (state, payload) => {
    state.vendors = Object.assign({}, payload)
  },
  UPDATE_VENDOR: (state, payload) => {
    state.vendors = Object.assign({}, payload)
  },
};

const actions = {
  async fetchVendors({ commit }) {
    await axios
    .get(`vendors`)
    .then((response) => {
      commit("SET_VENDORS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },
 
  async editVendor({ commit }, payload) {
    await axios
    .get(`vendors/${payload.vendor_id}/edit`)
    .then((response) => {
      commit("SET_VENDORS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async storeNewVendor({ commit }, payload) {
    await axios
      .post('vendors', payload)
      .then((response) => {
        commit("ADD_VENDOR", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateNewVendor({ commit }, payload) {
    await axios
      .patch(`vendors/${payload.vendor_id}`, payload.data)
      .then((response) => {
        commit("UPDATE_VENDOR", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteVendor({ commit }, payload) {
    await axios
      .delete(`vendors/${payload.vendor_id}`)
      .then((response) => {
        
      })
      .catch((err) => {
        console.log(err);
      });
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
