import axios from "../../api/api.shopcommander";

const state = {
  appointments: [],
 
  
};

const getters = {
  fetchAppointments: (state) => {
    return state.appointments;
  },
  
  fetchUpcomingAppointments: (state) => {
    return state.appointments;
  },
  fetchPreviousAppointments: (state) => {
    return state.appointments;
  },
  fetchUsers: (state) => {
    return state.appointments;
  }
};

const mutations = {
  SET_APPOINTMENTS: (state, payload) => {
    state.appointments = payload;
  },
  SET_APPOINTMENT: (state, payload) => {
    state.appointment = payload;
  },
  ADD_APPOINTMENT: (state, payload) => {
    state.appointments = Object.assign({}, payload)
  },
  UPDATE_APPOINTMENT: (state, payload) => {
    state.appointments = Object.assign({}, payload)
  },

  REMOVE_APPOINTMENT: (state, id) => {
    (state.appointments = state.appointments.filter((item) => item.id !== id))
  },
  ALLDAY: (state, payload) => {
   state.appointments.all_day = payload;
  },
  SEND_CONFIRMATION: (state, payload) => {
    state.appointments.send_confirmation = payload;
   },
   SEND_REMINDER: (state, payload) => {
    state.appointments.send_reminder = payload;
   }
};

const actions = {

  async getAppointments({ commit }, payload) {
    await axios
      .get(`appointments`)
      .then((response) => {
       commit("SET_APPOINTMENTS", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  
  },
  async selectUsername({ commit }) {
    await axios
      .post(`appointments/selectUsername`
      )
      .then((response) => {
        commit("SET_APPOINTMENTS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
   async upcomingAppointments({ commit }) {
    await axios
      .post(`appointments/upcomingappointment`
      )
      .then((response) => {
        commit("SET_APPOINTMENTS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async previousAppointments({ commit }) {
    await axios
      .post(`appointments/previousappointment`
      )
      .then((response) => {
        commit("SET_APPOINTMENTS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async editAppointment({ commit }, payload) {
    await axios
    .get(`appointments/${payload.event_id}/edit`)
    .then((response) => {
      commit("SET_APPOINTMENTS", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async storeNewAppointment({ commit }, payload) {
    await axios
      .post("appointments", payload)
      .then((response) => {
        commit("ADD_APPOINTMENT", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateAppointment({ commit }, payload) {
     await axios
      .patch(`appointments/${payload.event_id}`, payload.data)
      .then((response) => {
      commit("UPDATE_APPOINTMENT", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  
  async deleteAppointment({ commit }, payload) {
     await axios
      .delete(`appointments/${payload.event_id}`)
      .then((response) => {
        commit("REMOVE_APPOINTMENT", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

 
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
